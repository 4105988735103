import { getText } from "../../helpers/translateHelper"
import Modal from "../Modal/Modal";
import icons from "../../config/icons"
import OTPInput from "../OtpInput"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../Redux"
import { useEffect, useState } from "react"
import { useLockedBody } from 'usehooks-ts';
import { getPhoneNumberFromLs, isAppSettingFeatureEnable, getFormattedAmBal } from "../../utils/commonUtils";
import { callValidateAMBalancePin } from "../../Redux/slices/balanceSlice";
import AMBarredPopup from "../AMBarredPopup";
import { useNavigate } from "react-router-dom";
import { airtelMoney, CHANGE_PIN_ROUTE, HOME_PAGE } from "../../routes/routeConstants";
import { sendGA4Event } from "../../services/ga4";
import { AM_PIN_LENGTH, FORGOT_PIN_TEXT } from "../../data/constants";
import { callAmUserProfile } from "../../helpers/userInfoHelper";
import { isAMBarred, isLockedUser, isPinset, isResetPin, isPayXEnabled } from "../../helpers/commonHelpers";

interface AMPinModalInterface {
  price?: string;
  handlePinSubmit?: (...args: any[]) => void;
  closeModal?: (...args: any[]) => void;
  handlePinError?: (...args: any[]) => void;
  handlePinEnter?: () => void;
  heading?: string;
  handleSubmit?: (pin: string) => void;
  msg?: string;
  isShowAmt?: boolean;
  selectedCurrency?: string
}

export const AMPinModal = ({ price, handlePinSubmit, closeModal, handlePinError, handlePinEnter, heading, handleSubmit, msg, isShowAmt = false, selectedCurrency }: AMPinModalInterface) => {

  const [errMsg, setErrMsg] = useState<string>(""),
    [isUserBarred, setUserBarred] = useState<boolean>(false),
    [showBarredPopup, setShowBarredPopup] = useState<boolean>(false),
    [isPinValidating, setPinValidating] = useState<boolean>(false),
    currency = useSelector((state: RootState) => state.config?.basicConfig?.currency[0]),
    isAmMasked = useSelector((state: RootState) => state.amStore.isAmMasked),
    amProfile = useSelector((state: RootState) => state.home.amProfile),
    dispatch = useDispatch(),
    navigate = useNavigate();

  let amBalance: any = useSelector((state: RootState) => state?.balance?.balance?.amBal)?.[selectedCurrency ?? currency]?.total ?? 0;

  useLockedBody(true, 'root');

  useEffect(() => {
    setErrMsg(msg ?? '')
  }, [msg]);

  const handleSendGAEvents = (action: string) => {
    sendGA4Event({ category: 'balance', action: action });
  }

  const handleOnPinChange = (value: string) => {
    if (errMsg) {
      setErrMsg("");
      setPinValidating(false);
    }
    if (!isPinValidating && value.length === AM_PIN_LENGTH) {
      setPinValidating(true);
      if (handleSubmit) {
        handleSubmit(value)
      } else {
        validatePin(value);
      }
    }
  };


  function validatePin(pin: string = "") {
    let msisdn = getPhoneNumberFromLs();
    handlePinEnter && handlePinEnter();
    const validateAMPinPayload = {
      data: {
        msisdn: msisdn,
        pin,
      },
      successCallback: () => {
        handlePinSubmit && handlePinSubmit(pin);
        setPinValidating(false);
        handleSendGAEvents('am_pin_verify_success')
        // sendGA4Event({ category: "balance", action: `am_pin_verify_success` });
      },
      errorCallback: (msg: string) => {
        setErrMsg(msg);
        setPinValidating(false)
        !!handlePinError && handlePinError(msg);
        handleSendGAEvents('am_pin_verify_failed')
        // sendGA4Event({ category: "balance", action: `am_pin_verify_failed` });
      },
      errorPageCallback: (msg: string) => {
        // toggleBarredPopup();
        callAmUserProfile();
        setPinValidating(false)
        setErrMsg(msg);
        setUserBarred(true);
        handleSendGAEvents('found_that_wallet_barred')
        // sendGA4Event({ category: "balance", action: `found_that_wallet_barred` });
      }
    }
    dispatch(callValidateAMBalancePin(validateAMPinPayload));
  }

  function closeBarredPopup() {
    if (isUserBarred) {
      callAmUserProfile();
      navigate(HOME_PAGE);
    }
    setShowBarredPopup(!showBarredPopup);
    closeModal && closeModal();
  }

  function gotoForgotPin() {
    sendGA4Event({ category: "forgotPin", action: `forgotpin_${amProfile.isLowBalanceUser ? "lowval" : "highval"}_forgot_clicked` });
    navigate(airtelMoney.FORGOT_PIN);
  }

  const forgotPinBtnHide = () => {
    let btnHideFlag = !isPinset() || isAMBarred() || isResetPin() || isLockedUser() || !isAppSettingFeatureEnable(FORGOT_PIN_TEXT);
    if (isPayXEnabled()) btnHideFlag = btnHideFlag || amProfile?.blockedByInvalidPin;
    return btnHideFlag;
  }

  return (
    <>
      {!showBarredPopup ?
        <Modal
          isOpen={true}
          onClose={() => { }}
        >
          <div className="fixed top-[130px] bottom-auto left-0  am-pin-ctn w-[100vw] h-[1000px] rounded-[20px] rounded-br-none rounded-bl-none bg-white flex flex-col items-center pb-20">
            {closeModal && <div className="close-icn absolute right-16 top-18" onClick={closeModal}>
              <img className="w-24 h-24" src={icons.closeIcn} alt="close-icn" />
            </div>}
            <div className="lock-icn pt-24">
              <img src={icons.lock} alt="lock-icn" />
            </div>
            {price ?
              <div className="deducted-ctn mt-12">
                <div className="currency-ctn text-center">
                  <span className="currency text-[#E30000] font-bold text-18">{selectedCurrency ?? currency}</span>
                  <span className="price-ctn ml-8 text-[#293F55] font-bold text-18">{price ?? ""}</span>
                </div>
                <div className="deducted-text text-[#293F55] font-bold text-13">
                  {getText("DEDUCTED_TEXT")}
                </div>

              </div>
              :
              null
            }
            {!isAmMasked && isShowAmt ?
              <div className="am-balance-ctn text-12 font-bold text-[#707070] mt-8">
                {getText("AIRTEL_MONEY")} {getText("BALANCE")} -
                <span className="currency text-[#EC1B24]"> {selectedCurrency ?? currency}</span> <span className="text-[#293F55]">{getFormattedAmBal(amBalance, selectedCurrency ?? currency)}</span>
              </div>
              :
              null
            }
            <div className="heading-text pt-16 text-[#293F55] text-14 font-bold text-center px-46">
              {heading || getText("ENTER_AM_PIN")}
            </div>
            {errMsg ?
              <>
                <div className="incorrect-pin-text text-[#EC1B24] text-center text-13 font-bold mt-6 px-20 py-10">
                  {errMsg}
                </div>
                {/* <div className="attempts-left-ctn text-[#1F2436] font-bold text-13">{getText("ATTEMPTS_LEFT")}</div> */}
              </>
              :
              null
            }
            <div className="am-pin-wrapper pt-20 pb-17">
              <OTPInput autoFocus isNumberInput length={4} className="pin-ctn ml-54 flex justify-between"
                inputClassName={`outline-0 pin-input required flex-1 w-[22px] ml-[25px] h-[22px] max-w-[22px]  bg-[#DEDEDE] rounded-full text-center first:ml-0`}
                onChangeOTP={handleOnPinChange}
                handleSubmit={handlePinSubmit}
                isReset={!!errMsg}
                isClearBtnEnable={true}
              />
            </div>
            <div className={`border-line w-[40%] mb-10 bg-[#DEDEDE] rounded-xl h-2 ${errMsg ? "bg-[#EC1B24]" : ""}`}></div>
            {forgotPinBtnHide() ? null :
              <div onClick={gotoForgotPin} className="forgot-pin text-[#5A35F4] text-14 font-bold pt-15 pb-[25px]">{getText("FORGOT_PIN")}</div>
            }
          </div>
        </Modal>
        :
        <AMBarredPopup
          closeModal={closeBarredPopup}
        />
      }
    </>
  )
}
