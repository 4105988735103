import { PayloadAction } from "@reduxjs/toolkit";
import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ToastNotifyError } from "../../../components/ToastNotify";
import { ENDPOINTS } from "../../../config/endpoints";
import api from "../../../services/apiService";

import { RootState } from "../..";
import { featureNames } from "../../../config/codes";
import { IGetBetaFeaturesResp } from "../../../config/commonInterfaces";
import {
  callAMUserInfo,
  callAddFavourite,
  callDeleteFavourite,
  callEditFavourite,
  callFetchFavourites,
  callFetchFavouritesFailed,
  callFetchFavouritesSuccess,
  callFetchTransactionFee,
  callGetBetaFeatures,
  callGetBetaFeaturesSuccess,
  callGetLOB,
  callGetLOBFailed, callGetLOBSuccess,
  callGetRecentContactsBundle,
  callGetRecentContactsRecharge,
  callGetRecentContactsSendMoney,
  callGetTxnFee,
  callGetTxnFeeFailed, callGetTxnFeeSuccess, callProcessTrxn, callProcessTxnV2 as callProcessTxnV2, fetchAllFavourites, fetchBetaUsers, fetchBetaUsersSuccess, setCheckLobData, setCustomLoader, setHistoryData,
  callCheckTransactionLimit
} from "../../slices/globalSlice";
import { SOME_ERR } from "../../../data/constants";
import { getText } from "../../../helpers/translateHelper";
import { sendGA4Event } from "../../../services/ga4";
import { getIsGuestUser, getPhoneNumberFromLs } from "../../../utils/commonUtils";
import { stopLoader } from "../../../helpers/commonHelpers";

function* fetchTxnFee(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.txnFee(),
      data: payload.data
    });
    if (!!response?.data && response?.status === 200) {
      yield put(callGetTxnFeeSuccess(response.data));
      !!action?.payload?.callback && action.payload.callback(response.data.result?.serviceCharge);
    } else {
      !!response.data?.message && ToastNotifyError(response.data.message);
      yield put(callGetTxnFeeFailed(response));
    }
  } catch (error) {
    // console.log("Something went wrong", error);
    yield put(callGetTxnFeeFailed(error));
  }
}

function* fetchLob(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.lob,
      data: payload.data
    });
    if (response?.data?.statusCode === 200) {
      yield put(callGetLOBSuccess(response.data));
      const isAirtel = !!response.data.result?.airtelNumber,
        isPostpaidNum = !!response.data.result?.postpaidNumber,
        isBroadband = !!response.data.result?.broadband;

      !!action?.payload?.callback && action.payload.callback?.(isAirtel, isPostpaidNum, isBroadband);
      const msisdn = payload.data.siNumber;

      //v2 callback
      action?.payload?.callbackV2?.(isAirtel && !isPostpaidNum && !isBroadband, isBroadband);
      put(setCheckLobData({ msisdn, data: { isAirtel, isPostpaidNum, isBroadband } }));
    }
    else {
      action?.payload?.errorCallback?.(response?.data?.message);
      yield put(callGetLOBFailed(response));
    }
  } catch (error) {
    yield put(setCustomLoader(false));
    yield put(callGetLOBFailed(error));
  }
}

function* processTrxn(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.processTxn(),
      data: payload.data
    });
    if (!!response?.data && response?.status === 200 && response?.data?.statusCode === 200) {
      !!action?.payload?.successCallback && action.payload.successCallback(response.data);
    }
    else {
      !!action?.payload?.errorCallback && action.payload.errorCallback(response?.data);
    }
  } catch (error) {
    // console.log("Something went wrong", error);
    !!action?.payload?.errorCallback && action.payload.errorCallback(error);
  }
}

function* getRecentContactsRecharge(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.recentContacts(),
      data: payload.data,
      hideLoader: payload.hideLoader
    });
    if (response?.data?.statusCode === 200 && response?.data?.result?.data) {
      yield put(setHistoryData({ type: payload.data.transactionTypes[0], data: response.data.result.data }))
      action?.payload?.callback?.(response.data.result.data);
    }
  } catch (error) {
    action?.payload?.errorCallback?.(error);
  }
}

function* getRecentContactsSendMoney(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.recentContacts(),
      data: payload.data,
      hideLoader: payload.hideLoader
    });
    if (response?.data?.statusCode === 200 && response?.data?.result?.data) {
      yield put(setHistoryData({ type: payload.data.transactionTypes[0], data: response.data.result.data }))
      action?.payload?.callback?.(response.data.result.data);
    }
  } catch (error) {
    action?.payload?.errorCallback?.(error);
  }
}

function* getRecentContactsBundle(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.recentContacts(),
      data: payload.data,
      hideLoader: payload.hideLoader
    });
    if (response?.data?.statusCode === 200 && response?.data?.result?.data) {
      yield put(setHistoryData({ type: payload.data.transactionTypes[0], data: response.data.result.data }))
      action?.payload?.callback?.(response.data.result.data);
    }
  } catch (error) {
    action?.payload?.errorCallback?.(error);
  }
}

function* getFavourites(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.FAVOURITES.fetchFavourites,
      data: payload.data,
      hideLoader: payload.hideLoader
    });
    if (!!response?.data && response?.data?.statusCode === 200) {
      yield put(callFetchFavouritesSuccess({ favouritesType: payload.favouritesType, data: response?.data?.result?.favourites }));
    } else {
      yield put(callFetchFavouritesFailed())
    }
  } catch (error) {
    yield put(callFetchFavouritesFailed())
  }
}

function* getBetaFeatures(action: PayloadAction<any>): Generator {
  const allBetaFeatures = (yield select((state: RootState) => state.config?.launchConfig?.allBetaFeatureCodes)) as string[];
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",

      url: ENDPOINTS.betaFeatures,
      data: payload.data,
    });
    if (response?.data?.statusCode === 200) {
      (yield select((state: RootState) => state.config?.basicConfig?.invalidAMPinStatuses)) as number[]
      const betaFeatures = (response.data?.result?.availabilityList);
      yield put(callGetBetaFeaturesSuccess({ allBetaFeatures, betaFeatures }));
      !!action?.payload?.callback && action.payload.callback(response.data);
    }
  } catch (error) {
    // console.log(error);
  }
}

function* addFavourite(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.FAVOURITES.addFavourites,
      data: payload.data
    });
    if (!!response?.data && response?.data?.statusCode === 200) {
      !!action?.payload?.callback && action.payload.callback(response?.data);
    } else {
      !!action?.payload?.errorCallback && action.payload.errorCallback(response.data?.message);
    }
  } catch (error) {
    // console.log("Something went wrong", error);
  }
}

function* delFavourite(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.FAVOURITES.deleteFavourites,
      data: payload.data
    });
    if (!!response?.data && response?.data?.statusCode === 200) {
      !!action?.payload?.callback && action.payload.callback(response?.data);
    } else {
      !!action?.payload?.errorCallback && action.payload.errorCallback(response.data?.message);
    }
  } catch (error) {
    // console.log("Something went wrong", error);
  }
}

function* editFavourite(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.FAVOURITES.editFavourites,
      data: payload.data
    });
    if (!!response?.data && response?.data?.statusCode === 200) {
      !!action?.payload?.callback && action.payload.callback(response?.data);
    } else {
      !!action?.payload?.errorCallback && action.payload.errorCallback(response.data?.message);
    }
  } catch (error) {
    // console.log("Something went wrong", error);
  }
}

function* allFavouriteFetch(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.FAVOURITES.fetchAllFavourites,
      data: payload.data
    });
    if (!!response?.data && response?.data?.statusCode === 200) {
      !!payload?.callback && payload.callback(response?.data);
    } else {
      !!action?.payload?.errorCallback && action.payload.errorCallback(response.data?.message);
    }
  } catch (error) {
    // console.log("Something went wrong", error);
  }
}

function* fetchAllBetaUsers(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "GET",
      url: ENDPOINTS.betaUsers
    });
    if (response?.data?.statusCode === 200 && !!response?.data?.result?.data) {
      yield put(fetchBetaUsersSuccess(response.data.result.data));
      payload.callback(response.data.result.data);
    }
  } catch (error) {

  }
}

function* callProcessTransactionV2(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.getMPESAProcessTxn(payload.data.paymentMode),
      data: payload.data,
      hideLoader: payload?.hideLoader
    });
    if (response?.data?.statusCode === 200 && !!response?.data?.result) {
      payload.successCallback && payload.successCallback(response.data.result);
    } else {
      !!action?.payload?.errorCallback && action.payload.errorCallback(response?.data);
    }
  } catch (error) {

  }
}

function* fetchTransactionFee(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.transactionFeeV2,
      data: payload.data
    });
    console.log(response?.data?.statusCode === 200 && !!response?.data?.result)
    if (response?.data?.statusCode === 200 && !!response?.data?.result) {
      payload.callback && payload.callback(response.data.result);
      sendGA4Event({ category: "Transaction Fees API", action: "transaction_fees_api_success" });
    } else {
      ToastNotifyError(getText(SOME_ERR));
      payload?.errorCallback();
      sendGA4Event({ category: "Transaction Fees API", action: "transaction_fees_api_error" });
    }
  } catch (e) { }
}

function* fetchAMUserInfo(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.HOME.getAMProfile(),
      data: payload.data
    });
    if (response?.data?.statusCode === 200 && !!response?.data?.result) {
      payload?.callback(response.data.result);
    } else {
      payload?.errorCallback();
    }
  } catch (error) {

  }
}

function* checkTransactionLimit(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.checkTransactionLimit,
      data: payload.data
    });
    if (response?.data?.statusCode === 200) {
      payload?.callback();
    } else if (response?.data?.statusCode === 4006) {
      payload?.errorCallback();
    } else {
      ToastNotifyError(getText(SOME_ERR));
    }
  } catch (e) { }
}

export default function* root(): Generator {
  yield takeLatest(callGetTxnFee.type, fetchTxnFee);
  yield takeLatest(callGetLOB.type, fetchLob);
  yield takeLatest(callProcessTrxn.type, processTrxn);
  yield takeLatest(callGetBetaFeatures.type, getBetaFeatures);
  yield takeLatest(callGetRecentContactsRecharge.type, getRecentContactsRecharge)
  yield takeLatest(callGetRecentContactsSendMoney.type, getRecentContactsSendMoney)
  yield takeLatest(callGetRecentContactsBundle.type, getRecentContactsBundle)
  yield takeEvery(callFetchFavourites.type, getFavourites);
  yield takeLatest(callAddFavourite.type, addFavourite);
  yield takeLatest(callDeleteFavourite.type, delFavourite);
  yield takeLatest(callEditFavourite.type, editFavourite);
  yield takeLatest(fetchAllFavourites.type, allFavouriteFetch);
  yield takeLatest(callProcessTxnV2.type, callProcessTransactionV2);
  yield takeLatest(callFetchTransactionFee.type, fetchTransactionFee);
  yield takeLatest(callCheckTransactionLimit.type, checkTransactionLimit);
  yield takeLatest(callAMUserInfo.type, fetchAMUserInfo);
  yield takeLatest(fetchBetaUsers.type, fetchAllBetaUsers);
}
