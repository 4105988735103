import { Navigate } from 'react-router-dom'
import { getAuthTokenFromLs, getIsGuestUser } from '../utils/commonUtils';
import { HOME_PAGE } from './routeConstants';
import { resetData } from '../helpers/commonHelpers';
import { ReactElement } from 'react';
import { ACCESS_TYPES } from '../data/constants';

interface IAuthRoute {
  accessType: string | string[];
  children: ReactElement;
}

/**
 * 
 * @param authRoute type object with keys accessType of route and the component it navigates to with its path detail.
 * @returns After checking the correct type of route it returns the route to be available for user to navigate be it protected, guest or public route.
 */
export const AuthRoute = ({ accessType, children }: IAuthRoute) => {
  const isProtected = accessType === ACCESS_TYPES.PROTECTED;
  if (isProtected) {
    return getIsGuestUser() || getAuthTokenFromLs() ? children : <Navigate to="/" replace />
  } else {
    return getAuthTokenFromLs() ? <Navigate to={HOME_PAGE} replace /> : children;
  }
}

/**
 * 
 * @returns the default route in case the user enters some non-existant route
 */
export const getDefaultElement = () => {
  if (!getAuthTokenFromLs() && !getIsGuestUser()) {
    resetData();
    return <Navigate to="/" replace />
  } else {
    return <Navigate to={HOME_PAGE} replace />
  }
}