import { createSlice } from "@reduxjs/toolkit";
import { IConfigSliceProps } from "../../config/commonInterfaces";
import { clearStore } from "../actions";
import { EMPTY_APP_CONFIG, PREPAID_RECHARGE, V2 } from "../../data/constants";

const initialState: IConfigSliceProps = {
  launchConfig: {
    downloadLink: "",
    phoneCode: "",
    lang: "",
    otpTimer: "",
    msisdnLength: "",
    ga4Id: "",
    betaUsers: [],
    allBetaFeatureCodes: [],
    disableRightClick: false,
    amOptions: [],
    gsmBalConfigs: [],
    appSettingsConfigs: [EMPTY_APP_CONFIG],
    helpAndSupportConfig: {
      contactUsConfigs: [],
      findUsOnConfigs: []
    },
    version: V2,
    featureMap: {},
    popularRecharges: [],
    buyGoodsConfig: null as any,
    withdrawCashConfig: {
      agentCodeRegex: "",
      agentCodeLen: 0,
      agentCodeInputMode: "numeric"
    },
    paymentMethodConfig: null as any,
    MPESAConfig: {
      remainingTimeSecs: 0.1 * 60,
      enquiryIntervalMillis: 1000,
      enquiriesAllowed: 1
    },
    showShare: false,
    sessionTimeout: 10,
    autoLogout: false,
    showExitPopup: false,
    buyBundleConfig: {
      tabsId: [],
      bundleEventObj: {} as any,
      bundleGuestEventObj: {} as any,
      bundleAllowedCategories: [],
      bundleAllowedPeriodsWithOrder: []
    },
    enableSecureSms: false,
    secureSmsNonSupportedBrowsers: [],
    secureSmsTimeout: 0,
    secureSmsDelay: 0,
    transactionLimConfig: null as any,
    amTransactionHistoryConfig: null as any,
    supportedBrowsers: {},
    minimumScreenWidth: "",
    noAuthCheckRoutes: [],
    numberOfFetchAMTransactions: 0,
    locateUsLink: "",
    languageConfig: {
      supportedLanguages: []
    },
    heInitUrl: "",
    autoHeInit: false,
    heMandatory: true,
    heUnsupportedBrowser: {},
    isHeDisable: true,
    heOtpFlowEnable: true,
    isTempPinEnabledInOPCO: false,
    isDisableOtpPaste: true,
    isChangePin: false,
    guestConfig: {
      isEnabled: false,
      remainingTimeSecs: 0.1 * 60,
      enquiryIntervalMillis: 1000,
      enquiriesAllowed: 1,
      transactionLimConfig: {
        IS_ENABLED: false,
        PREPAID_RECHARGE: false
      }
    },
    securityQuestions: {
      ansMinLength: 4,
      ansMaxLength: 16
    },
    betaFeatureMap: {},
    ussd_am_code: "",
    enableUnlockPin: true,
    dualCurrency: {
      usdConversionValue: 100
    },
    amountMaxLength: 20,
    showAmBalDecimal: true,
    eventsEnabled: false,
    payXEnabled: false,
    tipsForSetPin: [1, 2, 3]
  },
  basicConfig: {
    customerCareNo: "",
    msisdnLength: "",
    backgroundThreadFreq: "",
    currency: [],
    ussdGSMCode: "",
    ussdAMCode: "",
    otpAttempts: "",
    recentContactsCount: "",
    invalidAMPinStatuses: [],
    bundleType: [],
    rechargeAirtimeFav: undefined,
    buyBundleFav: undefined,
    sendMoneyFav: undefined,
    ussdPayBillCode: ""
  },

  limitConfig: {
    airtimeRecharge: {},
    sendMoneyLocal: {},
    withdrawCashLimit: {}
  },
  homeConfig: [],
  homeConfigTimestamp: "",
  balanceConfig: []
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    callConfigApi: (state, action) => {
    },
    configApiSucess: (state, action) => {
    },
    configApiFailed: (state, action) => {
    },

    //launch config
    callLaunchConfigApi: (state, action) => {

    },
    launchConfigApiSucess: (state, action) => {
      if (!state.launchConfig) {
        state.launchConfig = { ...initialState.launchConfig };
      }
      const configData = action.payload?.data?.[0];
      state.launchConfig = {
        lang: configData?.locale ?? "",
        downloadLink: configData?.downloadLink ?? "",
        phoneCode: configData?.phone_code ?? "",
        otpTimer: configData?.otp_timer ?? "",
        msisdnLength: configData?.msisdn_length ?? "",
        ga4Id: configData?.ga4Id ?? "",
        betaUsers: configData?.beta_users ?? [],
        allBetaFeatureCodes: configData?.allBetaFeatureCodes ?? [],
        disableRightClick: configData?.disable_right_click ?? false,
        amOptions: configData?.amOptions ?? [],
        gsmBalConfigs: configData?.gsmBalConfigs ?? [],
        appSettingsConfigs: configData?.appSettingsConfigs ?? [EMPTY_APP_CONFIG],
        helpAndSupportConfig: {
          contactUsConfigs: configData?.helpAndSupportConfig?.contactUsConfigs ?? [],
          findUsOnConfigs: configData?.helpAndSupportConfig?.findUsOnConfigs ?? []
        },
        version: configData?.version ?? V2,
        featureMap: configData?.feature_map ?? {},
        popularRecharges: configData?.popularRecharges ?? [],
        buyGoodsConfig: configData?.buyGoodsConfig ?? null as any,
        withdrawCashConfig: {
          agentCodeRegex: configData?.withdrawCashConfig?.agentCodeRegex ?? "",
          agentCodeLen: configData?.withdrawCashConfig?.agentCodeLen ?? 0,
          agentCodeInputMode: configData?.withdrawCashConfig?.agentCodeInputMode ?? "numeric"
        },
        paymentMethodConfig: configData?.paymentMethodConfig ?? null as any,
        MPESAConfig: {
          remainingTimeSecs: configData?.MPESAConfig?.remainingTimeSecs ?? 0.1 * 60,
          enquiryIntervalMillis: configData?.MPESAConfig?.enquiryIntervalMillis ?? 1000,
          enquiriesAllowed: configData?.MPESAConfig?.enquiriesAllowed ?? 1
        },
        showShare: configData?.showShare ?? false,
        sessionTimeout: configData?.sessionTimeout ?? 10,
        autoLogout: configData?.autoLogout ?? false,
        showExitPopup: configData?.showExitPopup ?? false,
        buyBundleConfig: {
          tabsId: configData?.buyBundleConfig?.tabsId ?? [],
          bundleEventObj: configData?.buyBundleConfig?.bundleEventObj ?? {} as any,
          bundleGuestEventObj: configData?.buyBundleConfig?.bundleGuestEventObj ?? {} as any,
          bundleAllowedCategories: configData?.buyBundleConfig?.bundleAllowedCategories ?? [],
          bundleAllowedPeriodsWithOrder: configData?.buyBundleConfig?.bundleAllowedPeriodsWithOrder ?? []
        },
        enableSecureSms: configData?.enableSecureSms ?? false,
        secureSmsNonSupportedBrowsers: configData?.secureSmsNonSupportedBrowsers ?? [],
        secureSmsTimeout: configData?.secureSmsTimeout ?? 0,
        secureSmsDelay: configData?.secureSmsDelay ?? 0,
        transactionLimConfig: configData?.transactionLimConfig ?? null as any,
        amTransactionHistoryConfig: configData?.amTransactionHistoryConfig ?? null as any,
        supportedBrowsers: configData?.supportedBrowsers ?? {},
        minimumScreenWidth: configData?.minimumScreenWidth ?? "",
        noAuthCheckRoutes: configData?.noAuthCheckRoutes ?? [],
        numberOfFetchAMTransactions: configData?.numberOfFetchAMTransactions ?? 0,
        locateUsLink: configData?.locateUsLink ?? "",
        languageConfig: {
          supportedLanguages: configData?.languageConfig?.supportedLanguages ?? []
        },
        heInitUrl: configData?.heInitUrl ?? "",
        autoHeInit: configData?.autoHeInit ?? false,
        heMandatory: configData?.heMandatory ?? true,
        heUnsupportedBrowser: configData?.heUnsupportedBrowser ?? {},
        isHeDisable: configData?.isHeDisable ?? true,
        heOtpFlowEnable: configData?.heOtpFlowEnable ?? true,
        isTempPinEnabledInOPCO: configData?.isTempPinEnabledInOPCO ?? false,
        isDisableOtpPaste: configData?.isDisableOtpPaste ?? true,
        isChangePin: configData?.isChangePin ?? false,
        guestConfig: {
          isEnabled: configData?.guestConfig?.isEnabled ?? false,
          remainingTimeSecs: configData?.guestConfig?.remainingTimeSecs ?? 0.1 * 60,
          enquiryIntervalMillis: configData?.guestConfig?.enquiryIntervalMillis ?? 1000,
          enquiriesAllowed: configData?.guestConfig?.enquiriesAllowed ?? 1,
          transactionLimConfig: {
            IS_ENABLED: configData?.guestConfig?.transactionLimConfig?.IS_ENABLED ?? false,
            PREPAID_RECHARGE: configData?.guestConfig?.transactionLimConfig?.PREPAID_RECHARGE ?? false,
          }
        },
        securityQuestions: {
          ansMinLength: configData?.securityQuestions?.ansMinLength ?? 4,
          ansMaxLength: configData?.securityQuestions?.ansMaxLength ?? 16
        },
        dualCurrency: {
          usdConversionValue: configData?.dualCurrency?.usdConversionValue ?? 100
        },
        betaFeatureMap: configData?.betaFeatureMap ?? {},
        ussd_am_code: configData?.ussd_am_code ?? "",
        enableUnlockPin: configData?.enableUnlockPin ?? true,
        amountMaxLength: configData?.amountMaxLength ?? 20,
        showAmBalDecimal: configData?.showAmBalDecimal ?? true,
        eventsEnabled: configData?.eventsEnabled ?? false,
        payXEnabled: configData?.payXEnabled ?? false,
        tipsForSetPin: configData?.tipsForSetPin ?? [1, 2, 3]
      }

      // basic config data at launch has been addded here to the store state
      if (!state.basicConfig) {
        state.basicConfig = { ...initialState.basicConfig };
      }
      state.basicConfig = {
        ...state.basicConfig,
        customerCareNo: configData?.customer_care_no,
        msisdnLength: configData?.msisdn_length,
        backgroundThreadFreq: configData?.background_thread_freq,
        currency: configData?.currency,
        ussdGSMCode: configData?.ussd_gsm_code,
        ussdAMCode: configData?.ussd_am_code,
        otpAttempts: configData?.otp_attempts,
        bundleType: configData?.bundleType,
        invalidAMPinStatuses: configData?.invalidAMPinStatuses,
        rechargeAirtimeFav: configData?.rechargeAirtimeFav,
        buyBundleFav: configData?.buyBundleFav,
        sendMoneyFav: configData?.sendMoneyFav,
        ussdPayBillCode: configData?.ussdPayBillCode,
        recentContactsCount: state?.basicConfig?.recentContactsCount && configData?.recentContactsCount,
      }

      // limit config data at launch has been addded here to the store state
      if (!state.limitConfig) {
        state.limitConfig = { ...initialState.limitConfig };
      }
      state.limitConfig = {
        ...state.limitConfig,
        airtimeRecharge: configData?.airtime_recharge,
        sendMoneyLocal: configData?.send_money_local,
        withdrawCashLimit: configData?.withdrawCashLimit,
      }
    },
    launchConfigApiFailed: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder //clear config store
      .addCase(clearStore, (state, action) => {
        return initialState;
      })?.addDefaultCase((state, action) => { })
  }
});

export const {
  callConfigApi,
  configApiSucess,
  configApiFailed,
  callLaunchConfigApi,
  launchConfigApiSucess,
  launchConfigApiFailed,
} = configSlice.actions;
export default configSlice.reducer;
