import { store } from "../Redux";
import { BASE_API_URL } from "../config/endpoints";
import { APPLITE, PRODUCTION } from "../data/constants";
import { EventTypes } from "../data/eventTypes";
import { routesEventMap } from "../routes/routesEventMap";
import { getAuthTokenFromLs, getPhoneNumberFromLs } from "../utils/commonUtils";

export const eventTrackerInitialize = () => {
  try {
    if ((window as any)?.hasOwnProperty('eventTrackerInit')) {
      const eventTracker = (window as any)?.eventTrackerInit();
      if (eventTracker) {
        eventTracker?.initialize({
          eventBaseURL: BASE_API_URL,
          channel: "AppLite",
          xCountry: process.env?.REACT_APP_IS_PROD !== "true" ? process.env.REACT_APP_X_COUNTRY : undefined,
          msisdn: getPhoneNumberFromLs(),
          header: process.env?.REACT_APP_IS_PROD !== "false" ? { OPCO: process.env.REACT_APP_OPCO } : {},
        });
      }
    }
  } catch (error) {
    console.log("Error initializing event tracker", error);
  }
}

export const sendPageViewEvent = () => {
  sendEvent({ eventType: EventTypes.PAGE_VIEW, action: `${routesEventMap[window?.location?.pathname]}_page_view` });
}

export const sendEvent = ({ eventType, ...rest }: SendEvent) => {
  try {
    if ((window as any)?.hasOwnProperty('eventTrackerInit')) {
      const eventTracker = (window as any)?.eventTrackerInit();
      if (eventTracker && store.getState().config?.launchConfig?.eventsEnabled) {
        const date = new Date();
        eventTracker.send({
          eventType: eventType,
          token: getAuthTokenFromLs(),
          timeStamp: date.toISOString(),
          msisdn: getPhoneNumberFromLs(),
          ...rest
        });
      }
    }
  } catch (error) {
    console.log("Error sending event", error);
  }
}

export interface SendEvent {
  eventType: string;
  [key: string]: string | number | boolean | object | null | undefined;
}