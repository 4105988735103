import { BEST_OFFER_EVENT, FAILURE_EVENT, RECENT_CONTACTS_TXN_TYPE_P2P, RECENT_CONTACTS_TXN_TYPE_PREPAID_BUNDLE, RECENT_CONTACTS_TXN_TYPE_PREPAID_RECHARGE, SELECT_EVENT, SUCCESS_EVENT } from "../data/constants";
import { buyBundle, recharge } from "../routes/routeConstants";

export interface ITxnFeePayload {
  data: {
    msisdn: string;
    siNumber: string;
    flowType: string;
    price: string;
    currency: string;
    payerBankId: string;
    payeeBankId: string;
    payeeGrade: string;
    tillno: string;
    subFlowType: string;
    operator: string;
    operatorType: string;
    receivingCountryCode: string;
    atmCode: string;
    agentCode: string;
    isBPFlow: boolean;
    "x-consumer-txn-id": string;
  };

  //fee is the fetched txn fee
  callback: (fee: string) => void
}

export interface IGetLobPayload {
  data: {
    "siNumber": string,
    "flowType": ""
  },
  callback: (isAirtel: boolean, isPostpaidNum: boolean, isBroadband: boolean) => void,
  errorCallback: (errorMsg: string) => void
}

//saga paylaod
export interface IGetLobPayloadV2 {
  data: {
    "siNumber": string,
    "flowType": ""
  },
  callbackV2: (isPrepaid: boolean) => void,
  errorCallback: (errorMsg: string) => void
}

export interface IRechargePayload {
  data: {
    amount: number;
    requestingMsisdn: string;
    recipientMsisdn: string;
    currencyCode: string;
    currency: string;
    transactionType: string;
    paymentMode: string;
    pin: string;
    recipientName: string;
    isSegmentedBundle: boolean;
    autoRenew: boolean;
  },
  successCallback: (args: any) => void;
  errorCallback: (args: any) => void

}

export interface IBundleBuyPayload {
  data: {
    lob: string;
    siNumber: string;
    amount: string;
    requestingMsisdn: string;
    recipientMsisdn: string;
    validity: string;
    nextRenewalDate: string,
    amountdisplayText: string,
    currencyCode: string,
    currency: string,
    transactionType: string,
    subTransactionType: string,
    paymentMode: string,
    pin?: any;
    recipientName: string,
    isBPFlow: boolean,
    isSegmentedBundle: boolean,
    productCode: string,
    fname: string,
    lname: string,
    autoRenew: boolean,
    bundleName: string;
  },
  successCallback: (args: any) => void;
  errorCallback: (args: any) => void
}

export interface IRecentPhoneNumberParentFuncArgs {
  //customer name
  name: string,
  //amount from recent contacts
  recentContactsTxnAmount?: string
}

export interface IRecentContactsPayload {
  "msisdn": string,
  "count": string,
  "transactionTypes": [TRecentContactsTransactionTypes]
}


export type TRecentContactsTransactionTypes = typeof RECENT_CONTACTS_TXN_TYPE_PREPAID_BUNDLE | typeof RECENT_CONTACTS_TXN_TYPE_PREPAID_RECHARGE | typeof RECENT_CONTACTS_TXN_TYPE_P2P;

export interface IRecentContactsData {
  payeeMsisdn: string,
  payeeName: string,
  amount: string
}

export const FavouritesType = {
  rechargeAirtimeFav: 'rechargeAirtimeFav' as const,
  buyBundleFav: 'buyBundleFav' as const,
  sendMoneyFav: 'sendMoneyFav' as const
};

export type TFavourites = keyof typeof FavouritesType;

export interface IFavConfig {
  transactionType: string,
  category: string
}

export interface IConfigSliceProps {
  basicConfig: {
    customerCareNo: string,
    msisdnLength: string,
    backgroundThreadFreq: string,
    currency: string[],
    ussdGSMCode: string,
    ussdAMCode: string,
    otpAttempts: string,
    recentContactsCount: string;
    invalidAMPinStatuses: number[];
    bundleType: any;
    [FavouritesType.rechargeAirtimeFav]: IFavConfig | undefined;
    [FavouritesType.buyBundleFav]: IFavConfig | undefined;
    [FavouritesType.sendMoneyFav]: IFavConfig | undefined;
    ussdPayBillCode: string;
  };

  limitConfig: {
    airtimeRecharge: {
      [key: string]: {
        min: string,
        max: string
      }
    };
    sendMoneyLocal: {
      [key: string]: {
        min: string,
        max: string
      }
    };
    withdrawCashLimit: {
      [key: string]: {
        min: string,
        max: string
      }
    };
  };

  homeConfig: any;
  homeConfigTimestamp: string;
  balanceConfig: Array<any>;
  launchConfig: {
    downloadLink: string,
    phoneCode: string,
    lang: string,
    otpTimer: string,
    msisdnLength: string,
    ga4Id?: string,
    betaUsers: Array<string>,
    allBetaFeatureCodes: string[],
    gsmBalConfigs: IGSMBalConfig[],
    amOptions: Array<Array<string>>,
    version: string,
    disableRightClick: boolean,
    featureMap: any,
    appSettingsConfigs: IAppSettingsConfig[],
    helpAndSupportConfig: {
      contactUsConfigs: IContactUsConfig[],
      findUsOnConfigs: IFindUsOnConfig[]
    },
    buyGoodsConfig: {
      vendorConfigs: {
        code: string,
        operator: string,
        paymentMode: string,
        txnType: string,
        event: string,
        tillConfig: {
          regex: string,
          minLength: number,
          maxLength: number,
          inputMode: "tel" | "search" | "text" | "email" | "url" | "none" | "numeric" | "decimal" | undefined
        },
        amountConfig: {
          [key: string]: {
            min: number,
            max: number
          }
        }
      }[]
    },
    popularRecharges: number[],
    withdrawCashConfig: {
      agentCodeRegex: string,
      agentCodeLen: number,
      agentCodeInputMode: "tel" | "search" | "text" | "email" | "url" | "none" | "numeric" | "decimal" | undefined
    },
    paymentMethodConfig: {
      showDetails: boolean
    },
    MPESAConfig: {
      remainingTimeSecs: number,
      enquiryIntervalMillis: number,
      enquiriesAllowed: number
    },
    showShare: boolean;
    sessionTimeout: number;
    autoLogout: boolean;
    showExitPopup: boolean;
    buyBundleConfig: {
      tabsId: { heading: string, event: string }[],
      bundleEventObj: { [key: string]: Record<ACTION, string> },
      bundleGuestEventObj: { [key: string]: Record<ACTION, string> };
      bundleAllowedCategories: Array<string>,
      bundleAllowedPeriodsWithOrder: Array<string>
    },
    enableSecureSms: boolean;
    secureSmsNonSupportedBrowsers: ISecureSmsNonSupportedBrowsersConfig[];
    secureSmsTimeout: number,
    secureSmsDelay: number,
    transactionLimConfig: TransactionLimConfig,
    amTransactionHistoryConfig: {
      navTab: {
        data: { heading: string, code: string }[]
      },
      serviceTypes: any
    },
    numberOfFetchAMTransactions: number
    supportedBrowsers: any;
    minimumScreenWidth: string;
    noAuthCheckRoutes: string[];
    locateUsLink: string;
    languageConfig: {
      supportedLanguages: { code: string, label: string }[]
    }
    heInitUrl: string;
    autoHeInit: boolean;
    heMandatory: boolean;
    heUnsupportedBrowser: any;
    isHeDisable: boolean;
    heOtpFlowEnable: boolean;
    isTempPinEnabledInOPCO: boolean;
    isDisableOtpPaste: boolean;
    isChangePin: boolean;
    guestConfig: {
      isEnabled: boolean;
      remainingTimeSecs: number;
      enquiryIntervalMillis: number;
      enquiriesAllowed: number;
      transactionLimConfig: any
    };
    securityQuestions: {
      ansMinLength: number;
      ansMaxLength: number;
    },
    betaFeatureMap: any;
    ussd_am_code: string;
    enableUnlockPin: boolean;
    dualCurrency: {
      usdConversionValue: number;
    }
    amountMaxLength: number;
    showAmBalDecimal: boolean;
    eventsEnabled: boolean;
    payXEnabled: boolean;
    tipsForSetPin: any
  }
}

export type IGoodsVendorConfig = IConfigSliceProps["launchConfig"]["buyGoodsConfig"]["vendorConfigs"][0];

export interface IAppSettingsConfig {
  code: string,
  icon: string,
  type: "CLICK" | "CUSTOM_PAGE" | "STATIC_PAGE" | "",
  paramObj: { pageUrl: string };
  isGuest?: boolean;
}

export interface IContactUsConfig {
  code: string,
  icon: string,
  content: string,
  hrefType: "tel" | "mailto" | ""
}

export interface IFindUsOnConfig {
  code: string,
  icon: string,
  url: string
}

export interface ISecureSmsNonSupportedBrowsersConfig {
  osName: string,
  browserName: string
}

export interface IFetchedFav {
  "subCategory": string,
  "amount": string,
  "favouriteValue": {
    "favData": IFavMetaData[]
  },
  "updatedBy": string,
  "nickName": string,
  "partyValue": string,
  "favouriteType": string,
  "favouriteGroup": string,
  "partyType": string,
  "createdOn": string,
  "transactionType": string,
  "unit": string,
  "createdBy": string,
  "referenceNumber": string,
  "id": number
}

export interface IBundleFav extends PartialBy<IFetchedFav, "favouriteValue">, IBundleFavMetaData {

}

export interface IFav extends PartialBy<IFetchedFav, "favouriteValue">, ICommonFavMetaData {

}

export interface IBundleFavMetaData extends ICommonFavMetaData {
  bundleId: string,
  bundleType: string,
  bundleName: string,
  validity: string
}

/**
 * Will be used for meta data common to bundle, recharge and send money favourites
 */
export interface ICommonFavMetaData {

}

export interface ISelectedPack {
  packName: string,
  price: string,
  packId: string,
  validity: string
}

export interface IFavMetaData {
  key: string,
  value: string
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export interface IGetBetaFeaturesResp {
  featureName: string;
  availabilityType: string;
}

/**
 * Payload received in launch config.
 * Used to render gsm bal card in the dashboard
 */
export interface IGSMBalConfig {
  isEnabled: boolean,
  types: string[],
  footerLabel: string,
  footerRoute: string,
  event: string,
  decimalLimit: any
}

/**
 * A msisdn which MAY contain 0 or phonecode as prefix  
 */
export type PrefixedMsisdn = { value: string };

//location data accepted by payment options page
export interface IPaymentOptionsLocData {
  from: typeof recharge.RECHARGE | typeof buyBundle.BUNDLE_MENU
  data: {
    operator: string;
    amount: string,
    txnFee: number,
    // receipientName: string,
    msisdn: string,
    heading: string,
    benefitsName: string;
    benefitsMsg: string;
    productCode?: string;
    paymentMode?: string;
    bundleCategory?: string,
    bundleIsBestOffer?: boolean,
    isGuestUser?: boolean;
    currency?: string;
    otherAmount?: string;
  }
}

/**
 * Payment option received from api
 */
export interface IPaymentOptions {
  "balance": number,
  "name": string,
  "applicable": boolean,
  "minTxnAmount": number,
  "currencyCode": string,
  "paymentType": string
}

/**
 * Fetch payment option request 
 */
export interface IPaymentOptionsReq {
  transactionType: string
}
export type FLOW = "P2P" | "P2PI" | "PREPAID_RECHARGE" | "CASHOUT" | "IS_ENABLED" | "PREPAID_BUY_BUNDLES";

type TransactionLimConfig = Record<FLOW, boolean> & { isEnabled: boolean };

export type ACTION = typeof SUCCESS_EVENT | typeof FAILURE_EVENT | typeof SELECT_EVENT | typeof BEST_OFFER_EVENT
