import { store } from "../Redux";
import { PrefixedMsisdn } from "../config/commonInterfaces";
import { AUTH_TOKEN, FORGOT_PIN_TEXT, IPHONE, IS_GUEST_USER, MANAGE_QUESTION, PHONE_NUMBER, ZERO_DECIMAL, EN, LANGUAGE_CAPS } from "../data/constants"
import { addSpaceInMsisdn, getDeviceInfo, isPinset, isAMBarred, isResetPin, isLockedUser, isAmAccountActive, isPayXEnabled } from "../helpers/commonHelpers";
import { getCookieValue, getLocalStorage, getSessionStorage, removeLocalStorage, removeSessionStorage, setLocalStorage, setSessionStorage } from "./localStorage"
import * as Bowser from "bowser";
import { CHANGE_PIN } from "../data/constants";
import { PAY_OPTION_MPESA } from "../data/constants";
import { checkIsBetaFeatureAvailable } from "../features/beta/helpers/betaHelper";

export const setPhoneNumberToLs = (value: string) => {
  setLocalStorage(PHONE_NUMBER, value);
  return;
}

export const getPhoneNumberFromLs = () => {
  if (getIsGuestUser()) {
    return "";
  }
  let number = getLocalStorage(PHONE_NUMBER) || "";
  number = parseMsisdn(number);
  return number;
}

export const getRawPhoneNumber = () => {
  let number = getLocalStorage(PHONE_NUMBER) || "";
  return number;
}

export const deletePhoneNumberFromLs = () => {
  removeLocalStorage(PHONE_NUMBER);
  return;
}

export const setAuthTokenToLs = (value: string) => {
  setSessionStorage(AUTH_TOKEN, value);
  return;
}

export const getAuthTokenFromLs = () => {
  return getSessionStorage(AUTH_TOKEN);
}

export const deleteAuthToeknFromLs = () => {
  removeSessionStorage(AUTH_TOKEN);
  return;
}

export const checkIos = () => {
  const device = getDeviceInfo();
  if (device.OSName === IPHONE)
    return true;
  return false;
}

//make input number in gabon and madagascar atleast 9 digits long by prepending 0's
export const parseMsisdn = (msisdn: string = "") => {
  let number: string = msisdn, opco = process.env.REACT_APP_OPCO;
  //TODO use frm launch config
  const msisdnLengthLim = 9;
  if (opco === "GA" && number.length < msisdnLengthLim)
    number = `0${msisdn}`;
  return number;
}

export const removeZeroFromStart = (msisdn: string = "") => {
  let number: string = msisdn;
  return number && number.replace(/^0+/, '')
}

export const removePrefix = (msisdn: PrefixedMsisdn) => {
  if (!msisdn)
    return "";
  let resultMsisdn = removeZeroFromStart(msisdn.value);
  const phoneCode = store.getState()?.config?.launchConfig?.phoneCode;
  if (!phoneCode)
    return resultMsisdn;
  return resultMsisdn.startsWith(phoneCode) ? resultMsisdn.substring(phoneCode.length) : resultMsisdn;
}

/**
 * Validate permissible chars and len in input prefixed msisdn
 * The input msisdn might not be having all the characters.
 */
export const validateInputPrefixedMsisdn = (input: string, phoneCode: string, msisdnLength: string) => {
  if (!input)
    return false;

  //only allow numbers and +
  if (/[^+0-9]/g.test(input))
    return false;
  //dont allow 2 zeroes in starting
  if (input.startsWith("00"))
    return false;

  //if starts with +, allow if phone code prefix
  if (input.startsWith("+") && (input.length === +msisdnLength + (phoneCode || "").length) && !input.startsWith(phoneCode)) {
    return false;
  }

  return validatePartialPrefixedMsisdnLen(input, phoneCode, msisdnLength);
}

/**
 * Check if input len <= max len
 * @param input 
 * @param phoneCode 
 * @param msisdnLenLim msisdn len
 */
export const validatePartialPrefixedMsisdnLen = (input: string, phoneCode: string, msisdnLenLim: string) => {

  if (input.startsWith("+") && input.length <= (+msisdnLenLim) + phoneCode.length)
    return true;

  if (input.startsWith("0") && input.length <= (+msisdnLenLim) + 1)
    return true
  return input.length <= +msisdnLenLim;
}

export const validatePrefixedMsisdnLen = (input: string, phoneCode: string, msisdnLenLim: string) => {
  if (input.startsWith("+"))
    return input.length === (+msisdnLenLim) + phoneCode.length;
  else if (input.startsWith("0"))
    return input.length === (+msisdnLenLim) + 1;
  else
    return input.length === +msisdnLenLim;
}

/*
 * Converts a detailed msisdn to UI friendly format
 */
export const getFormattedMsisdn = (msisdn: PrefixedMsisdn) => {
  if (!msisdn)
    return "";
  return addSpaceInMsisdn(removePrefix(msisdn));
}

/** 
 * Get backend compat msisdn
 */
export const getBackendCompatMsisdn = (input: PrefixedMsisdn) => {
  return parseMsisdn(input.value);
}

/**
 * Use to render amount to UI
 * @param amount to be converted into decimal
 * @returns amount with decimal
 */
export const formatAmount = (amount: Number, minimumFractionDigits: number = 2, maximumFractionDigits: number = 2): string => {
  if (!amount)
    return ZERO_DECIMAL;
  return amount.toLocaleString(undefined, { maximumFractionDigits, minimumFractionDigits });
}

export const checkValidBrowser = () => {
  const supportedBrowsers = store.getState()?.config?.launchConfig?.supportedBrowsers || {};
  const bowser = Bowser.getParser(window.navigator.userAgent),
    browserName = bowser.getBrowserName();
  const isValidBrowser = bowser.satisfies(supportedBrowsers) || !Object.keys(supportedBrowsers).includes(browserName);
  return isValidBrowser;
}

export const checkMinimumScreenSize = () => {
  let isMinimum = false;
  const minimumScreenSize = store.getState().config?.launchConfig?.minimumScreenWidth;
  const screenWidth = window.screen.width;
  isMinimum = screenWidth >= +minimumScreenSize;
  return isMinimum;
}

export const getPhoneCodeAndMsisdn = (msisdn: string) => {
  const phoneCode = store.getState()?.config?.launchConfig?.phoneCode || "";
  return (phoneCode + " " + addSpaceInMsisdn(msisdn));
}

export const isSanitizedInput = (input: string) => {
  const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  const isXSS = regex.test(input);
  return !isXSS;
}

export const getAuthTokenFromCookie = () => (
  getCookieValue("tn")
);

export const getMsisdnFromCookie = () => (
  getCookieValue("ms")
);

export const getHeStatusFromCookie = () => (
  getCookieValue("st")
);

export const getHeFailureMsgFromCookie = () => (
  getCookieValue("flm")
);

export const isWifiConnected = () => {
  let windowObj: any = window;
  let type = windowObj?.navigator?.connection?.type;
  if (type === "wifi")
    return true;
  return false;
};

export const getOpcoDefaultLang = () => {
  const language = process.env.REACT_APP_LANG || EN;
  return language;
}

export const getUserLang = () => {
  const userLang = store.getState()?.home?.userInfo?.userConfig?.userLanguage || "";
  return userLang;
}

export const getLangLabel = (lang: any) => {
  const langOptions = store.getState()?.config?.launchConfig?.languageConfig?.supportedLanguages || [];
  const langInd = langOptions.findIndex((x) => x?.code === lang)
  if (langInd !== -1) return langOptions[langInd]?.label;
  else return lang;
}

export const setIsGuestUser = (value: boolean) => {
  setSessionStorage(IS_GUEST_USER, value);
  return;
}

export const getIsGuestUser = () => {
  return getSessionStorage(IS_GUEST_USER) === "true";
}

export const getAppConfigSettingData = () => {
  const isGuestUser = getIsGuestUser();
  const data = (store.getState()?.config?.launchConfig?.appSettingsConfigs).filter((setting: any, index) => {
    if (!setting?.isDisable) {
      if (isGuestUser) {  // condition to check for guest allowed appsettingconfig
        return setting?.isGuest;
      } else if (isHumburgOptionAllow(setting?.code) && (!setting?.betaCode || checkIsBetaFeatureAvailable(setting?.betaCode))) {
        return setting;
      }
    }
  });
  return data;
}

export const isHumburgOptionAllow = (code: string) => {
  let status = true;
  const blockedByInvalidPin: any = store.getState().home.amProfile?.blockedByInvalidPin;
  switch (code) {
    case CHANGE_PIN:
    case FORGOT_PIN_TEXT:
    case MANAGE_QUESTION:
      if (!isPinset() || isAMBarred() || isResetPin() || isLockedUser() || !isAmAccountActive() || !isAmServiceAvailable()) {
        status = false;
      } else if (isPayXEnabled() && blockedByInvalidPin) status = false;
      break;
    case LANGUAGE_CAPS:
      const langOptions = store.getState()?.config?.launchConfig?.languageConfig?.supportedLanguages || [];
      if (langOptions?.length < 2) status = false;
      break;
    default: break;
  }
  return status;
}

export const isMPESAOptionsAvailable = (paymentObtions: any) => {
  return Boolean(paymentObtions?.find(((el: any) => el?.name === PAY_OPTION_MPESA)));
}


export const isAppSettingFeatureEnable = (featureName: string) => {
  let flag = false;
  (store.getState()?.config?.launchConfig?.appSettingsConfigs).forEach((setting: any, index) => {
    if (setting?.code === featureName && !setting?.isDisable)
      flag = true;
  });
  return flag;
}

export const getAmmountInUSD = (amount: number) => {
  const conversionValue = store.getState()?.config?.launchConfig?.dualCurrency?.usdConversionValue;
  const updatedAmount = amount ? amount / conversionValue : null;
  return updatedAmount ? `${updatedAmount.toFixed(2)}` : '';
}

export const getTotalTransactionFees = (feeData: any) => {
  return +(parseFloat(feeData?.serviceCharge ?? 0) + parseFloat(feeData?.governmentTaxAmount ?? 0) + parseFloat(feeData?.commissionAmt ?? 0)).toFixed(2)
}

export const getTotalAmount = (amount: string, fees: Number) => {
  return formatAmount(+amount + Number(fees));
}

export const isDualCurrency = () => {
  return store.getState().config?.basicConfig?.currency?.length > 1
}

export const getFormattedAmBal = (amBalance: any, currency: string) => {
  let balance = amBalance;
  const showAmBalDecimal = store.getState()?.config?.launchConfig?.showAmBalDecimal;
  if (!showAmBalDecimal) balance = Math.trunc(balance)
  balance = balance?.toLocaleString(
    undefined,
    { maximumFractionDigits: 2 }
  )
  return balance;
}

export const isAmServiceAvailable = () => {
  return store.getState()?.home?.amProfile?.isAmServiceAvailable
} 