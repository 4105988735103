import { store } from "../../../Redux";
import { updateBetaFeatures, updateMenuOptions } from "../../../Redux/slices/featureSlice";
import { callGetBetaFeatures } from "../../../Redux/slices/globalSlice";
import { BETA_PERMISSIONS } from "../../../data/constants";
import { getPhoneNumberFromLs } from "../../../utils/commonUtils";

/**
 * Create available features object for the user. 
 * This object will be saved to redux store
 * 
 * @param mappedBetaFeatures: all mappedBetaFeatures from api resp
 * @param allBetaFeatures: allBetaFeatures from api resp
 * */
export function createAvailableFeaturesObj(allBetaFeatures: string[] = []) {
  let result: Record<string, boolean> = {};
  allBetaFeatures.forEach((feature: any) => {
    if (feature.availabilityType === BETA_PERMISSIONS.RESTRICTED || feature.availabilityType === BETA_PERMISSIONS.GA)
      result[feature.featureName] = true;
  })
  return result;
}

/**
 * Find if the input feature is available in the mappedBetaFeatures and allBetaFeatures
 * 
 * @param featureName: feature name
 * @param mappedBetaFeatureNames: features mapped to user received from api resp
 * @param allBetaFeatureNames: all beta features received from api resp
 * */


/**
 * Finds if the input feature is available in redux store
 * Used to check feature availability in components, render component if this function returns true
 * 
 * @param featureName: const string 
 * */
export function isFeatureAvailable(featureName: string) {
  featureName = featureName.toLowerCase();
  const availableFeatures = store.getState().featureStore?.availableFeatures;
  return !!availableFeatures?.[featureName];
}

export function getAvailableFeaturesList(obj: any = {}) {
  let result: any = {}, betaFeatures: any = [];
  Object.keys(obj).forEach((key) => {
    if (!obj?.[key]?.isBeta)
      result[key] = true;
    else {
      result[key] = false;
      betaFeatures.push(key);
    }
    if (obj?.[key]?.subfeatures) {
      Object.keys(obj?.[key]?.subfeatures).forEach((key2) => {
        result[key2] = true;
      })
    }
  })
  store.dispatch(updateBetaFeatures(betaFeatures));
  return result;
}

export function sortMenuOptions(obj: any = {}) {
  let data = Object.values(obj);
  console.log(data);
  data = data.filter((key: any) => key.type === "menu");
  data.sort((a: any, b: any) => a.position - b.position);
  data.forEach((key: any) => {
  });
  store.dispatch(updateMenuOptions(data));
}

export function callBetaService() {
  let payload = {
    data: {
      msisdn: getPhoneNumberFromLs(),
      deviceName: "web",
      buildNumber: 1
    }
  }
  store.dispatch(callGetBetaFeatures(payload));
}

export function checkIsBetaFeatureAvailable(code: string = "") {
  let betaFeatures = store.getState()?.globalStore?.availableBetaFeatures || {};
  return (betaFeatures[code]);
}