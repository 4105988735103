import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { ICompositeBalance, IInputGSMBalance } from "../../features/balance";
import { toICompositeBalance } from "../../features/balance/helpers/balanceMapper";
import { ApiResponse } from "../../lib/axios/ApiResponse";
import { clearStore } from "../actions";
import { DATE_FORMAT } from "../../data/constants";

export interface Props {
  balance: ICompositeBalance;
}

const initialState: Props = {
  balance: {
    airtimeBal: undefined,
    amBal: undefined,
    voiceBal: undefined,
    dataBal: undefined,
    smsBal: undefined,
    timestamp: undefined,
    refreshGsmBal: false,
    offlineTimeStamp: undefined,
    isAmMasked: true,
    amTimeStamp: "",
    bundlebyCategory: {},
    activePacks: [],
    isFetchingGSMBalance: false,
  },
};

export const balanceSlice = createSlice({
  name: "balance",
  initialState,
  reducers: {

    //check gsm balance api call reducer
    callCheckGSMBalance: (state, action) => {
      state.balance.isFetchingGSMBalance = true;
    },

    callCheckGSMBalanceSuccess: (state, action) => {
      const response = action.payload.data as ApiResponse;
      const inputGSMBalance = response.result as IInputGSMBalance;
      const newGsmBal = toICompositeBalance(inputGSMBalance);
      const ambal = state?.balance?.amBal;
      newGsmBal.timestamp = moment().format();
      newGsmBal.offlineTimeStamp = moment().format(DATE_FORMAT);
      state.balance = newGsmBal;
      state.balance.amBal = ambal;
      state.balance.bundlebyCategory = response.result?.bundlebyCategory;
      state.balance.activePacks = response.result?.activePacks;
      state.balance.refreshGsmBal = false
      state.balance.isFetchingGSMBalance = false;
    },

    callCheckGSMBalanceFailure: (state, action) => {
      state.balance.timestamp = moment().format();
      state.balance.offlineTimeStamp = moment().format(DATE_FORMAT)
      state.balance.refreshGsmBal = true
      state.balance.airtimeBal = undefined
      state.balance.amBal = state?.balance?.amBal || undefined
      state.balance.voiceBal = undefined
      state.balance.dataBal = undefined
      state.balance.isFetchingGSMBalance = false;
    },


    //check am balance api call reducer
    callCheckAMBalance: (state, action) => { },

    callCheckAMBalanceSuccess: (state, action) => {
      // if (!state.balance) {
      //   state.balance = { ...initialState.balance };
      // }

      // const amBal = action.payload?.data?.result?.balance?.toLocaleString(
      //   undefined,
      //   { maximumFractionDigits: 2 }
      // );
      // state.balance.amBal = {
      //   total: amBal,
      //   //stores the non trunc value
      //   value: action.payload?.data?.result?.balance,
      //   unit: ""
      // };
      // state.balance.amTimeStamp = moment().format();
      if (!state.balance) {
        state.balance = { ...initialState.balance };
      }

      state.balance.amBal = action.payload?.data?.result?.data?.reduce((prvObj: any, item: any) => {
        return {
          ...prvObj,
          [item.currency]: {
            total: item?.balance,
            value: item?.balance,
            unit: item?.currency
          }
        }
      }, {}) ?? {}

      state.balance.amTimeStamp = moment().format();
    },

    callCheckAMBalanceFailure: (state, action) => {
    },

    //validate am balance pin api call reducer
    callValidateAMBalancePin: (state, action) => { },

    callValidateAMBalancePinSuccess: (state, action) => { },

    callValidateAMBalancePinFailure: (state, action) => { }
  },

  extraReducers: (builder) => {
    builder //clears everything on 401
      .addCase(clearStore, (state) => {
        return initialState;
      })
      .addDefaultCase((state, action) => { })
  }
});

export const {
  callCheckGSMBalance,
  callCheckGSMBalanceSuccess,
  callCheckGSMBalanceFailure,
  callCheckAMBalance,
  callCheckAMBalanceSuccess,
  callCheckAMBalanceFailure,
  callValidateAMBalancePin,
  callValidateAMBalancePinSuccess,
  callValidateAMBalancePinFailure
} = balanceSlice.actions;

export default balanceSlice.reducer;
