import * as routes from "./routeConstants";

export const routesEventMap = {
      "/": "splash_screen",
      [routes.LOGIN_ROUTE]: "msisdn_entry",
      [routes.LOG_OUT]: "log_out",
      [routes.IN_COMPATIBLE]: "in_compatible",
      [routes.HE_Error]: "he_error",
      [routes.HOME_PAGE]: "dashboard",
      [routes.buyBundle.BUNDLE_MENU]: "buy_bundle",
      [routes.SUCCESS]: "transaction_status",
      [routes.recharge.RECHARGE]: "buy_airtime",
      [routes.APP_SETTINGS_ROUTE]: "app_settings_menu",
      [routes.HELP_AND_SUPPORT_ROUTE]: "app_settings_help_and_support",
      [routes.PAYMENT_METHODS_ROUTE]: "payment_options",
      [routes.SUCCESS_V2]: "transaction_status",
      [routes.FAILED_V2]: "transaction_status",
      [routes.STATIC_APP_SETTINGS_ROUTE]: "app_settings_static",
      [routes.IN_PROGRESS_ROUTE]: "transaction_status",
      [routes.balanceRoute.BALANCE]: "balance",
      [routes.AM_PIN_ERROR_RT]: "am_pin_error",
      [routes.SEND_MONEY]: "send_money",
      [routes.USSD_ROUTE]: "ussd",
      [routes.PAY_BILL]: "bill",
      [routes.MY_FAV]: "my_favourite",
      [routes.airtelMoney.TRANSACTION_HISTORY]: "transaction_history",
      [routes.WITHDRAW_CASH]: "withdraw_cash",
      [routes.BUY_GOODS_RT]: "buy_goods",
      [routes.balanceRoute.ACTIVE_PACKS]: "active_packs",
      [routes.airtelMoney.SET_QUESTION]: "manage_security_question",
      [routes.LANGUAGE_ROUTE]: "app_settings_language",
      [routes.TRANSACTION_DETAILS_ROUTE]: "transaction_summary",
      [routes.CHANGE_PIN_ROUTE]: "change_pin",
      [routes.airtelMoney.FORGOT_PIN]: "forgot_pin",
    };